<template>
  <div class="container">
    <h2>Проекты</h2>
    <template v-if="!error">
      <div class="filter-block">
        <InputFinder
          placeholder="Поиск"
          :isInstant="true"
          :autofocus="true"
          @changeValue="loadFilteredList"
        />
        <CheckboxFilterModal
          title="Статус"
          class="filter-block__status"
          :list="statuses"
          :selectedValues="statusFilter"
          @setValue="setStatus"
        />
      </div>
      <ProjectsTable :projectsList="projectsList" />
      <Pagination :data="projectsData" />
    </template>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { PROJECTS_STATUSES } from "@/assets/constants";

import CheckboxFilterModal from "@/components/CheckboxFilterModal";
import InputFinder from "@/components/InputFinder";
import ProjectsTable from "@/components/ProjectsTable";
import Pagination from "@/components/Pagination";
import loadPageMixin from "@/mixins/loadPageMixin";

export default {
  name: "ProjectsPage",
  components: {
    InputFinder,
    ProjectsTable,
    Pagination,
    CheckboxFilterModal,
  },
  mixins: [loadPageMixin],
  async created() {
    if (!this.$route.query.page) {
      await this.setSearch("");
    }
  },
  methods: {
    ...mapActions({
      loadProjectsList: "projectsStore/loadProjectsList",
      setPageNumber: "projectsStore/setPageNumber",
      setSearch: "projectsStore/setSearch",
      setStatusFilter: "projectsStore/setStatusFilter",
    }),
    ...mapMutations({
      refreshStatusFilter: "projectsStore/refreshStatusFilter",
    }),
    async loadFilteredList(text) {
      if (this.$route.query.page !== "1") {
        this.$router.replace({ query: { page: 1 } });
      }
      await this.setSearch(text);
    },
    async setStatus(status) {
      if (this.$route.query.page !== "1") {
        this.$router.replace({ query: { page: 1 } });
      }
      await this.setStatusFilter(status);
    },
  },
  computed: {
    ...mapState({
      projectsList: (state) => state.projectsStore.projectsList,
      projectsData: (state) => state.projectsStore.projectsData,
      filterTypes: (state) => state.projectsStore.filterTypes,
      statusFilter: (state) => state.projectsStore.statusFilter,
      error: (state) => state.projectsStore.error,
    }),
    ...mapGetters({
      filteredProjectsList: "projectsStore/filteredProjectsList",
    }),
    statuses() {
      return PROJECTS_STATUSES.map((status) => ({ id: status, name: status }));
    },
  },
  async destroyed() {
    this.refreshStatusFilter();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.filter-block__status {
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
