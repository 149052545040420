<template>
  <div class="projects">
    <StickyHeader>
      <div class="projects__head columns-template">
        <div class="projects__head-column">Проект</div>
        <div class="projects__head-column">Клиент</div>
        <div class="projects__head-column">Статус</div>
        <div class="projects__head-column">Стек технологий</div>
        <div class="projects__head-column">Проектные менеджеры</div>
      </div>
    </StickyHeader>
    <div class="projects__body-container">
      <div
        v-for="project of projectsList"
        :key="project.id"
        class="projects__body columns-template"
      >
        <div class="projects__body-column">
          {{ formatColumnData(project.name, "name") }}
        </div>
        <div class="projects__body-column">
          {{ formatColumnData(project.customer, "name") }}
        </div>
        <div class="projects__body-column">
          {{ formatColumnData(project.status, "status") }}
        </div>
        <div class="projects__body-column">
          {{ formatColumnData(project.tech_stacks) }}
        </div>
        <div class="projects__body-column">
          {{ formatColumnData(project.pms, ["last_name", "first_name"]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import StickyHeader from "@/components/StickyHeader.vue";

export default {
  name: "projects-table",
  components: {
    StickyHeader,
    // ChangeFieldElement,
  },
  props: {
    projectsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return "";
      return value[0].toUpperCase() + value.slice(1).toLowerCase();
    },
  },
  destroyed() {
    this.clearSearch();
    this.clearQualFilter();
    this.clearPositionFilter();
  },
  computed: {
    ...mapGetters({
      fullName: "staffStore/fullName",
    }),
  },
  methods: {
    ...mapMutations({
      clearSearch: "staffStore/clearSearch",
      clearQualFilter: "staffStore/clearQualFilter",
      clearPositionFilter: "staffStore/clearPositionFilter",
      setChangeFieldModalData: "modalStore/setChangeFieldModalData",
    }),
    openChangeFieldModal(value) {
      console.log("open modal");
      console.log(value);
      this.setChangeFieldModalData(value);
    },
    formatColumnData(data, fieldNames = "") {
      if (!Array.isArray(data)) {
        return data.name || data;
      } else if (!data.length) {
        return "-";
      } else {
        if (!fieldNames.length) {
          fieldNames = Object.keys(data[0]).filter((item) => item !== "id");
        } else {
          if (!Array.isArray(fieldNames)) {
            fieldNames = [fieldNames];
          }
        }

        return data.reduce((acc, item, index, array) => {
          let fullItem = "";
          fieldNames.forEach((fieldName, fieldNamesInd, fieldNamesArray) => {
            const whitespace =
              fieldNamesInd + 1 !== fieldNamesArray.length ? " " : "";
            fullItem += `${item[fieldName]}${whitespace}`;
          });
          return `${acc}${fullItem}${index + 1 !== array.length ? ", " : ""}`;
        }, "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.columns-template {
  display: grid;
  grid-template-columns: 220px 140px 150px 250px 240px;
}

.projects {
  width: min-content;
  background: linear-gradient($erp-primary-bg-color 40px, #ffffff 40px);
  padding: 0 3px;
  border-radius: 7px;
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  margin-top: 25px;
}

.projects__head {
  background-color: $erp-primary-bg-color;
  color: #ffffff;
  border-radius: 7px 7px 0 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
}

.projects__head-column:not(:first-child) {
  border-left: 1px solid #ffffff;
}

.projects__body-container {
  height: auto;
  scrollbar-width: thin;
}

.projects__body-container::-webkit-scrollbar {
  width: 2px;
}

.projects__body-container::-webkit-scrollbar-thumb {
  background-color: #c1d2dd;
  border-radius: 10em;
}

.projects__body {
  padding: 10px 0;
}

.projects__body:not(:last-child) {
  border-bottom: 1px solid #aaaaaa;
}

.projects__body-column {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  line-height: 20px;
}

.projects__body-column:not(:first-child) {
  justify-content: center;
  border-left: 1px solid #aaaaaa;
}

.projects__body-column-link {
  color: inherit;
}
</style>
